<template>
	<div>
	<contentHeader title="Data Obat" url="/data-obat" subTitle="Tambah Data Obat" />
	<div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
				<div class="col">
					<div class="card">
						<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Tambah Data Obat</h3>
	            		</div>
	            		<div class="card-body row">
	            			<div class="col-lg-2"></div>
	            			<div class="col-lg-8">
	            				<form @submit.prevent="prosesTambahObat">
		            				<div class="px-lg-2 ">
		            					<div class="form-group">
					                		<label for="kode">Kode Obat </label>
					                		<input type="text" class="form-control" id="kode" name="kode" required="" autocomplete="off" disabled="" v-model="obat.kode">
					                	</div>
					                	<div class="form-group">
					                		<label for="nama">Nama <span class="text-danger">*</span></label>
					                		<input type="text" class="form-control" id="nama" name="nama" autocomplete="off" v-model="obat.nama">
					                	</div>
										<div class="form-group">
					                		<label for="jenis">Jenis <span class="text-danger">*</span></label>
					                		<select class="custom-select form-control" v-model="obat.jenis" required="" >
					                			<option value="obat">Obat</option>
					                			<option value="percetakan">Percetakan</option>
					                			<option value="alat_kesehatan">Alat Kesehatan</option>
					                		</select>
					                	</div>
										<div class="form-group">
					                		<label for="jenis">Pabrik <span class="text-danger">*</span></label>
					                		<select class="custom-select form-control" v-model="obat.pabrik" required="" >
					                			<option v-for="(row, index) in pabrik" :key="index" :value="row.nama">{{ row.nama }}</option>
					                		</select>
					                	</div>
					                	<div class="form-group">
					                		<label for="kategori">Kategori <span class="text-danger">*</span></label>
					                		<select class="custom-select form-control" v-model="obat.kategori" required="" >
					                			<option v-for="(row, index) in kategori" :key="index" :value="row.nama">{{ row.nama }}</option>
					                		</select>
					                	</div>
					                	<div class="form-group" v-if="obat.jenis == 'obat'">
					                		<label for="nama">Kandungan <span class="text-danger">*</span></label>
											<select class="custom-select form-control" v-model="obat.kandungan" required="" >
					                			<option v-for="(row, index) in kandungan" :key="index" :value="row.nama">{{ row.nama }}</option>
					                		</select>
					                	</div>
					                	<div class="form-group">
					                		<label for="golongan">Golongan <span class="text-danger">*</span></label>
					                		<select class="custom-select form-control" v-model="obat.golongan" required="">
					                			<option v-for="(row, index) in golongan" :key="index" :value="row.nama">{{ row.nama }}</option>
					                		</select>
					                	</div>
					                	<div class="form-group">
					                		<label for="jenis">Type <span class="text-danger">*</span></label>
					                		<select class="custom-select form-control" v-model="obat.type" required="" >
					                			<option v-for="(row, index) in jenis" :key="index" :value="row.nama">{{ row.nama }}</option>
					                		</select>
					                	</div>
										<div class="form-group">
						                    <label>Satuan <span class="text-danger">*</span></label>
						                    <div class="row">
						                    	<div class="col">
						                    		<span>Satuan Besar</span>
						                    		<div class="input-group mb-3">
						                    		  <select class="custom-select form-control" v-model="obat.satuan_besar" required="">
														<option v-for="(row, index) in satuan" :key="index" :value="row.nama">{{ row.nama }}</option>
													</select>
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Satuan Kecil</span>
						                    		<div class="input-group mb-3">
						                    		  <select class="custom-select form-control" v-model="obat.satuan" required="">
														<option v-for="(row, index) in satuan" :key="index" :value="row.nama">{{ row.nama }}</option>
													</select>
									                </div>
						                    	</div>
						                    </div>
						                </div>
										<div class="form-group">
						                    <label>Jumlah <span class="text-danger">*</span></label>
						                    <div class="row">
						                    	<div class="col">
						                    		<span>Jumlah Satuan Besar</span>
						                    		<div class="input-group mb-3">
						                    		  <input type="number" class="form-control" v-model="obat.jumlah_satuan_besar" disabled>
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Jumlah Satuan Kecil</span>
						                    		<div class="input-group mb-3">
						                    		  <input type="number" class="form-control" v-model="obat.jumlah_satuan">
									                </div>
						                    	</div>
						                    </div>
						                </div>
					                	<div class="form-group">
					                		<label for="nama">Lokasi</label>
					                		<select class="custom-select form-control" v-model="obat.lokasi">
					                			<option v-for="(row, index) in lokasi" :key="index" :value="row.nama">{{ row.nama }}</option>
					                		</select>
					                	</div>
					                	<div class="form-group" v-if="obat.kategori.toLowerCase() == 'racikan'">
					                		<label for="bobot">Bobot Obat <span class="text-danger">*</span></label>
					                		<div class="row">
					                			<div class="col col-md-4">
					                				<div class="input-group mb-3">
						                				<input type="number" step="any" class="form-control" id="bobot" required="" name="bobot" v-model="obat.bobot">
						                				<div class="input-group-prepend">
										                  <div class="btn btn-secondary">Gram</div>
										                </div>
					                				</div>
					                			</div>
					                			<div class="col col-md-8"></div>
					                		</div>
					                	</div>
					                	<div class="form-group">
						                    <label>Harga <span class="text-danger">*</span></label>
						                    <div class="row">
						                    	<div class="col">
						                    		<span>Harga Beli</span>
						                    		<div class="input-group mb-3">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">Rp.</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" required="" name="harga_beli" v-model="obat.harga_beli">
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Harga Jual Klinik</span>
						                    		<div class="input-group mb-3">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">Rp.</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" required="" name="harga_jual" v-model="obat.harga_jual">
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Harga Jual Pasien</span>
						                    		<div class="input-group mb-3">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">Rp.</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" required="" name="harga_jual" v-model="obat.harga_jual_pasien">
									                </div>
						                    	</div>
						                    </div>
						                </div>
					                	<div class="form-group">
					                		<div class="row">
					                			<div class="col">
					                				<label for="minimal_stok">Minimal Stok <span class="text-danger">*</span></label>
					                				<input type="number" class="form-control" id="minimal_stok" name="minimal_stok" autocomplete="off" required="" v-model="obat.minimal_stok">	
					                			</div>
					                			<div class="col">
					                				<label for="maksimal_stok">Maksimal Stok <span class="text-danger">*</span></label>
					                				<input type="number" class="form-control" id="maksimal_stok" required="" name="maksimal_stok" autocomplete="off" v-model="obat.maksimal_stok">
					                			</div>
					                		</div>
					                	</div>
					                	<div class="form-group">
						                    <label>Fee </label>
						                    <div class="row">
						                    	<div class="col">
						                    		<span>Fee Dokter</span>
						                    		<div class="input-group">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">{{ label_fee_dokter }}</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" step="any" name="fee_dokter" v-model="obat.fee_dokter" @keyup="changeLabelFeeDokter">
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Fee Perawat</span>
						                    		<div class="input-group">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">{{ label_fee_beautician }}</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" step="any" name="fee_beautician" v-model="obat.fee_beautician" @keyup="changeLabelFeeBeautician">
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Fee Sales</span>
						                    		<div class="input-group">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">{{ label_fee_sales }}</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" step="any" name="fee_sales" v-model="obat.fee_sales" @keyup="changeLabelFeeSales">
									                </div>
						                    	</div>
						                    </div>
						                </div>
					                	<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
											<div>Submit</div>	
											<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
											  <span class="sr-only">Loading...</span>
											</div>
										</button>
		            				</div>
		            			</form>
	            			</div>
	            			<div class="col-lg-2"></div>
	            		</div>
					</div>
				</div>
			</div>
		</div>
	</div>
		
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader2'

	import { computed, reactive, ref, watch } from 'vue'
	import store from '@/store'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import router from '@/router'

	// Dispatch
	store.dispatch('dataDetailProduk/fetchPabrik')
	store.dispatch('dataDetailProduk/fetchKategori')
	store.dispatch('dataDetailProduk/fetchGolongan')
	store.dispatch('dataDetailProduk/fetchKandungan')
	store.dispatch('dataDetailProduk/fetchJenis')
	store.dispatch('dataDetailProduk/fetchSatuan')
	store.dispatch('dataDetailProduk/fetchLokasi')
	
	// Fetch Data
	const cabang 	= computed(() => store.getters['auth/cabang'])
	const pabrik 	= computed(() => store.getters['dataDetailProduk/getPabrik'])
	const kategori 	= computed(() => store.getters['dataDetailProduk/getKategori'])
	const golongan 	= computed(() => store.getters['dataDetailProduk/getGolongan'])
	const kandungan = computed(() => store.getters['dataDetailProduk/getKandungan'])
	const jenis 	= computed(() => store.getters['dataDetailProduk/getJenis'])
	const satuan 	= computed(() => store.getters['dataDetailProduk/getSatuan'])
	const lokasi 	= computed(() => store.getters['dataDetailProduk/getLokasi'])
	// const loading_dropdown = computed(() => store.getters['dataDetailProduk/loading'])
	const loading = ref(false)
	const obat = reactive({
		"cabang_id" : cabang.value.id,
		"kode" : "",
		"nama" : "",
		"pabrik" : "",
		"kategori" : "",
		"jenis" : "",
		"type" : "",
		"golongan" : "",
		"kandungan" : "",
		"satuan" : "",
		"satuan_besar" : "",
		"jumlah_satuan" : "",
		"jumlah_satuan_besar" : 1,
		"harga_beli" : "",
		"harga_jual" : "",
		"harga_jual_pasien" : "",
		"minimal_stok" : "",
		"maksimal_stok" : "",
		"bobot": "",
		"lokasi" : "",
		"fee_dokter" : "",
		"fee_beautician" : "",
		"fee_sales" : "",
	})

		watch(() => obat.golongan, (val) => console.log(val.toLowerCase()))
	const prosesTambahObat = () => {
		loading.value = true
		console.log(obat)
		obat.jenis != 'obat' ? obat.kandungan = '-' : ''
		axios.post(`api/produk/create`, obat)
		.then(() => {
			Swal.fire({
				title: 'Berhasil!',
				text: 'Berhasil Menambahkan Data',
				icon: 'success',
				showConfirmButton: false,
				timer: 1500
			})	
			loading.value = false
			obat.jenis == 'obat' ? router.push('/data-obat') : obat.jenis == 'percetakan' ? router.push('/data-percetakan') : router.push('/data-data-alat-kesehatan')  
		})
		.catch((error) => {
			Swal.fire({
				title: 'Gagal!',
				text: 'Gagal Menambahkan Data',
				icon: 'error',
				showConfirmButton: false,
				timer: 1500
			})	
			loading.value = false
			console.log(error)
		})
	}

	const label_fee_dokter = ref('...')

	const changeLabelFeeDokter = () => {
		if (obat.fee_dokter > 100) {
			label_fee_dokter.value = 'Rp. '
		} else if (obat.fee_dokter == '') {
			label_fee_dokter.value = '...'
		} else {
			label_fee_dokter.value = 'Persen (%) '
		}
	}

	const label_fee_beautician = ref('...')

	const changeLabelFeeBeautician = () => {
		if (obat.fee_dokter > 100) {
			label_fee_beautician.value = 'Rp. '
		} else if (obat.fee_beautician == '') {
			label_fee_beautician.value = '...'
		} else {
			label_fee_beautician.value = 'Persen (%) '
		}
	}

	const label_fee_sales = ref('...')

	const changeLabelFeeSales = () => {
		if (obat.fee_dokter > 100) {
			label_fee_sales.value = 'Rp. '
		} else if (obat.fee_beautician == '') {
			label_fee_beautician.value = '...'
		} else {
			label_fee_sales.value = 'Persen (%) '
		}
	}

	const getKode = async () => {
		await axios.get(`api/produk/getForGenerateCode/${cabang.value.kode_cabang}/${cabang.value.tipe}`)
		.then((response) => {
			obat.kode = response.data
		})
	}

	getKode()
	
</script>
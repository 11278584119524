<template>
	<TambahDataObatKecantikan v-if="cabang.tipe == 'kecantikan'" />
	<TambahDataObatUmum v-else />
</template>


<script setup>
import TambahDataObatUmum from './components/TambahDataObatUmum.vue'
import TambahDataObatKecantikan from './components/TambahDataObatKecantikan.vue'

import store from '@/store'
import { computed } from 'vue'

const cabang = computed(() => store.getters['auth/cabang'])
console.log(cabang.value)
</script>
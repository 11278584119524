<template>
	<div>
	<contentHeader title="Data Obat" url="/data-obat" subTitle="Tambah Data Obat" />
	<div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
				<div class="col">
					<div class="card">
						<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Tambah Data Obat</h3>
	            		</div>
	            		<div class="card-body row">
	            			<div class="col-lg-2"></div>
	            			<div class="col-lg-8">
	            				<form @submit.prevent="prosesTambahObat">
		            				<div class="px-lg-2 ">
		            					<div class="form-group">
					                		<label for="kode">Kode Obat </label>
					                		<input type="text" class="form-control" id="kode" name="kode" required="" autocomplete="off" disabled="" v-model="obat.kode">
					                	</div>
					                	<div class="form-group">
					                		<label for="nama">Nama <span class="text-danger">*</span></label>
					                		<input type="text" class="form-control" id="nama" name="nama" autocomplete="off" v-model="obat.nama">
					                	</div>
					                	<div class="form-group">
					                		<label for="kategori">Kategori <span class="text-danger">*</span></label>
					                		<select class="custom-select form-control" v-model="obat.kategori" required="" @change="generateKode()">
					                			<option selected="" hidden=""></option>
					                			<option value="BDL">Body Lotion</option>
					                			<option value="BDP">Bedak Padat</option>
					                			<option value="BET">Bedak Tabur</option>
					                			<option value="BER">BERL</option>
					                			<option value="BNC">Brigtening Night Cream</option>
					                			<option value="BSS">Body Serum/SCRUB</option>
					                			<option value="CCC">CC Cream</option>
					                			<option value="DBI">Derma Beauty Indah</option>
					                			<option value="DKE">Derma K Indah</option>
					                			<option value="EYE">Krim Mata</option>
					                			<option value="FTC">Facial Tonic</option>
					                			<option value="FWA">Facial Wash</option>
					                			<option value="HAT">Hair Treatment</option>
					                			<option value="IMM">Immortal</option>
					                			<option value="INJ">Injek</option>
					                			<option value="KDE">Kitoderm</option>
					                			<option value="LIP">Lipstik/Lipmate</option>
					                			<option value="MIC">Milk Cleanser</option>
					                			<option value="MKR">Masker</option>
					                			<option value="MOS">Moisturizer</option>
					                			<option value="NCK">Krim Leher</option>
					                			<option value="OOA">Obat Oral Antioksidan</option>
					                			<option value="OOG">Obat Oral General</option>
					                			<option value="OOS">Obat Oral Slimming</option>
					                			<option value="OOV">Obat Oral VItamin</option>
					                			<option value="PEC">Peeling Cream</option>
					                			<option value="RAC">Racikan</option>
					                			<option value="RSA">Ratnasari</option>
					                			<option value="SHA">Shampo</option>
					                			<option value="SKN">Skinnova</option>
					                			<option value="SPA">Lulur</option>
					                			<option value="SRM">Serum</option>
					                			<option value="SUN">Sunscreen</option>
					                			<option value="THE">Theraskin</option>
					                		</select>
					                	</div>
					                	<div class="form-group">
					                		<label for="jenis">Jenis <span class="text-danger">*</span></label>
					                		<select class="custom-select form-control" v-model="obat.jenis" required="" @change="generateKodeDanGetNomorUrut()">
					                			<option selected="" hidden=""></option>
					                			<option value="O">Obat</option>
					                			<option value="K">Kosmetik</option>
					                		</select>
					                	</div>
					                	<div class="form-group">
					                		<label for="fungsi">Fungsi </label>
					                		<textarea class="form-control" v-model="obat.fungsi"></textarea>
					                	</div>
					                	<div class="form-group">
					                		<label for="golongan">Golongan <span class="text-danger">*</span></label>
					                		<select class="custom-select form-control" v-model="obat.golongan" required="">
					                			<option selected="" hidden=""></option>
					                			<option value="bahan jadi">Bahan Jadi</option>
					                			<option value="bahan baku">Bahan Baku</option>
					                		</select>
					                	</div>
					                	<div class="form-group">
					                		<label for="nama">Satuan <span class="text-danger">*</span></label>
					                		<select class="custom-select form-control" v-model="obat.satuan" required="">
					                			<option selected="" hidden=""></option>
					                			<option value="pcs">Pcs</option>
					                			<option value="tube">Tube</option>
					                			<option value="ml">Ml</option>
					                			<option value="fls">Fls</option>
					                			<option value="cream">Cream</option>
					                			<option value="vial">Vial</option>
					                			<option value="bungkus">Bungkus</option>
					                			<option value="botol">Botol</option>
					                			<option value="tablet">Tablet</option>
					                			<option value="kaplet">Kaplet</option>
					                			<option value="gram">Gram</option>
					                			<option value="pot">Pot</option>
					                			<option value="strip">Strip</option>
					                			<option value="box">Box</option>
					                			<option value="ampl">Ampl</option>
					                			<option value="cc">CC</option>
					                			<option value="kotak">Kotak</option>
					                			<option value="lembar">Lembar</option>
					                			<option value="kapsul">Kapsul</option>
					                			<option value="kaleng">Kaleng</option>
					                		</select>
					                	</div>
					                	<div class="form-group" v-if="toggleInputBobot">
					                		<label for="bobot">Bobot Obat <span class="text-danger">*</span></label>
					                		<div class="row">
					                			<div class="col col-md-4">
					                				<div class="input-group mb-3">
						                				<input type="number" step="any" class="form-control" id="bobot" required="" name="bobot" v-model="obat.bobot">
						                				<div class="input-group-prepend">
										                  <div class="btn btn-secondary">Gram</div>
										                </div>
					                				</div>
					                			</div>
					                			<div class="col col-md-8"></div>
					                		</div>
					                	</div>
					                	<div class="form-group">
						                    <label>Harga <span class="text-danger">*</span></label>
						                    <div class="row">
						                    	<div class="col">
						                    		<span>Harga Beli</span>
						                    		<div class="input-group mb-3">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">Rp.</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" required="" name="harga_beli" v-model="obat.harga_beli">
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Harga Jual</span>
						                    		<div class="input-group mb-3">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">Rp.</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" required="" name="harga_jual" v-model="obat.harga_jual">
									                </div>
						                    	</div>
						                    </div>
						                </div>
					                	<div class="form-group">
					                		<div class="row">
					                			<div class="col">
					                				<label for="minimal_stok">Minimal Stok <span class="text-danger">*</span></label>
					                				<input type="number" class="form-control" id="minimal_stok" name="minimal_stok" autocomplete="off" required="" v-model="obat.minimal_stok">	
					                			</div>
					                			<div class="col">
					                				<label for="maksimal_stok">Maksimal Stok <span class="text-danger">*</span></label>
					                				<input type="number" class="form-control" id="maksimal_stok" required="" name="maksimal_stok" autocomplete="off" v-model="obat.maksimal_stok">
					                			</div>
					                		</div>
					                	</div>
					                	<div class="form-group">
					                		<label for="lokasi">Lokasi <span class="text-danger">*</span></label>
					                		<input type="text" class="form-control" id="lokasi" name="lokasi" required="" autocomplete="off" v-model="obat.lokasi">
					                	</div>
					                	<div class="form-group">
						                    <label>Fee </label>
						                    <div class="row">
						                    	<div class="col">
						                    		<span>Fee Dokter</span>
						                    		<div class="input-group">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">{{ label_fee_dokter }}</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" step="any" name="fee_dokter" v-model="obat.fee_dokter" @keyup="changeLabelFeeDokter">
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Fee Beautician</span>
						                    		<div class="input-group">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">{{ label_fee_beautician }}</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" step="any" name="fee_beautician" v-model="obat.fee_beautician" @keyup="changeLabelFeeBeautician">
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Fee Sales</span>
						                    		<div class="input-group">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">{{ label_fee_sales }}</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" step="any" name="fee_sales" v-model="obat.fee_sales" @keyup="changeLabelFeeSales">
									                </div>
						                    	</div>
						                    </div>
						                </div>
					                	<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
											<div>Submit</div>	
											<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
											  <span class="sr-only">Loading...</span>
											</div>
										</button>
		            				</div>
		            			</form>
	            			</div>
	            			<div class="col-lg-2"></div>
	            		</div>
					</div>
				</div>
			</div>
		</div>
	</div>
		
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader2'

	import { computed, reactive, onMounted, ref } from 'vue'
	import store from '@/store'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import tambah_nol from '@/tambah_nol'
	import router from '@/router'

	const user = computed(() => store.getters['auth/user'])
	const cabang = computed(() => store.getters['auth/cabang'])
    const loading = ref(false)
    const obat = reactive({
        "cabang_id" : "",
        "kode" : "",
        "nama" : "",
        "kategori" : "",
        "jenis" : "",
        "fungsi" : "",
        "golongan" : "",
        "satuan" : "",
        "harga_beli" : "",
        "harga_jual" : "",
        "minimal_stok" : "",
        "maksimal_stok" : "",
        "bobot": "",
        "lokasi" : "",
        "fee_dokter" : "",
        "fee_beautician" : "",
        "fee_sales" : "",
    })

    const { generateKode, generateKodeDanGetNomorUrut, getCabang, toggleInputBobot } = useGenerateKode(user, obat, cabang.value) 

    const prosesTambahObat = () => {
        loading.value = true
        axios.post(`api/produk/createKecantikan`, obat)
        .then((response) => {
            Swal.fire({
                title: 'Berhasil!',
                text: 'Berhasil Menambahkan Data',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            })	
            loading.value = false
            router.push('/data-obat')
            console.log(response)
        })
        .catch((error) => {
            Swal.fire({
                title: 'Gagal!',
                text: 'Gagal Menambahkan Data',
                icon: 'error',
                confirmButtonText: 'Ok'
            })	
            loading.value = false
            console.log(error)
        })
    }

    const label_fee_dokter = ref('...')

    const changeLabelFeeDokter = () => {
        if (obat.fee_dokter > 100) {
            label_fee_dokter.value = 'Rp. '
        } else if (obat.fee_dokter == '') {
            label_fee_dokter.value = '...'
        } else {
            label_fee_dokter.value = 'Persen (%) '
        }
    }

    const label_fee_beautician = ref('...')

    const changeLabelFeeBeautician = () => {
        if (obat.fee_dokter > 100) {
            label_fee_beautician.value = 'Rp. '
        } else if (obat.fee_beautician == '') {
            label_fee_beautician.value = '...'
        } else {
            label_fee_beautician.value = 'Persen (%) '
        }
    }

    const label_fee_sales = ref('...')

    const changeLabelFeeSales = () => {
        if (obat.fee_dokter > 100) {
            label_fee_sales.value = 'Rp. '
        } else if (obat.fee_beautician == '') {
            label_fee_beautician.value = '...'
        } else {
            label_fee_sales.value = 'Persen (%) '
        }
    }



    onMounted(() => {
        getCabang()
    })


	function useGenerateKode (user, obat, cabang) {
		const kode_cabang = ref()
		const nomor_urut = ref('')
		const toggleInputBobot = ref(false)

		const getCabang = async () => {
			await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)
	    	.then((response) => {
	    		kode_cabang.value = `${response.data.kode_cabang}`
	    		obat.cabang_id = response.data.id
	    	}) 
	    	.catch((error) => {
	    		console.log(error)
	    	})

	    	obat.kode = `${kode_cabang.value}-`
		}

		const getNomorUrut = async () => {
			await axios.get(`api/produk/getForGenerateCode/${obat.kode}/${cabang.tipe}`)
	    	.then((response) => {
	    		if(response.data == 'kosong'){
	    			nomor_urut.value = tambah_nol(1)
	    			generateKode()
	    		} else {
	    			let str = response.data
					let length = str.length
					let nlength = length - 3
					let id = str.slice(nlength, length) 
	    			id = Math.abs(id)
	    			nomor_urut.value = tambah_nol(id + 1)
	    			generateKode()
	    		}
	    	})
	    	.catch((error) => {
	    		console.log(error)
	    	})
		}

		const generateKode = () => {
			obat.kode = `${kode_cabang.value}-${obat.kategori}${obat.jenis}${nomor_urut.value}`

			if (obat.kategori == 'RAC') { toggleInputBobot.value = true } else { toggleInputBobot.value = false }
		}

		const generateKodeDanGetNomorUrut = () => {
			getNomorUrut()

			obat.kode = `${kode_cabang.value}-${obat.kategori}${obat.jenis}${nomor_urut.value}`
		}


		return {
			generateKode, generateKodeDanGetNomorUrut, getCabang, toggleInputBobot
		}
	}
</script>